<template>
    <div>
        <div class="chart-align">
            <div class="modal-header" style="display: flex; align-items: center;">
                <h3>계열사 출고수량</h3>&nbsp;&nbsp;<span><h4>{{ this.today }}kg</h4></span>
            </div>
            <hr/>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <JqxChart :key="'chart1-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart1" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[0]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[0]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart2-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart2" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[1]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[1]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart3-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart3" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[2]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[2]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                    </div>
                </div>
            </div>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <JqxChart :key="'chart4-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart4" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[3]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[3]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart5-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart5" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[4]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[4]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart6-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart6" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[5]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[5]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        
                    </div>
                </div>
            </div>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <JqxChart :key="'chart7-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart7" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[6]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[6]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart8-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart8" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[7]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[7]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart9-' + chartKey" class="jqx-chart" ref="affiliatedPartnerChart9" style="width: 33%; height: 200px;" :title="affiliatedPartnerTitle[8]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="affiliatedPartnerData[8]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import JqxChart from "jqwidgets-scripts/jqwidgets-vue/vue_jqxchart.vue";
    export default {
        components: {
            JqxChart,
        },
        props: {
            affiliatedPartnerList: {
                type: String,
                required: true
            },
            performanceList: {
                type: String,
                required: true
            },
            randomParam: {
                type: String,
            }
        },
        watch: {
            randomParam() {
                this.draw();
            }
        },
        mounted() {
            this.draw();
        },
        data: function () {
            return {
                today: '',
                affiliatedPartnerTitle: [],
                affiliatedPartnerData: Array(12).fill([{ text: '미출고', value: 0 },{ text: '출고중', value: 0 },{ text: '출고완료', value: 0 }]),
                padding: { left: 5, top: 5, right: 5, bottom: 5 },
                titlePadding: { left: 5, top: 5, right: 5, bottom: 5 },
                seriesGroups: [
                    {
                        type: 'donut',
                        useGradientColors: false,
                        series:
                            [
                                {
                                    showLabels: false,
                                    enableSelection: false,
                                    displayText: 'text',
                                    dataField: 'value',
                                    labelRadius: 120,
                                    initialAngle: 90,
                                    radius: 60,
                                    innerRadius: 40,
                                    centerOffset: 0,
                                    rotation: 'counterclockwise',
                                }
                            ]
                    }
                ],
            }
        },
        beforeCreate: function () {
            this.counter = 0;
            this.chartKey = 0;
        },
        methods: {
            drawBefore: function (renderer, rect) {
                let data = this.affiliatedPartnerData[this.counter] || [];
                let value = data.reduce((sum, item) => sum + item.value, 0);
                
                let sz = renderer.measureText(value, 0, { 'class': 'jqx-chart-inner-text' });
                renderer.text(
                    value,
                    rect.x + (rect.width - sz.width) / 2,
                    rect.y + rect.height / 2.25,
                    0,
                    0,
                    0,
                    { 'class': 'jqx-chart-inner-text'}
                );
                this.counter ++;
            },
            draw: function () {
                if(!Array.isArray(JSON.parse(this.performanceList))) return;

                let qtyTran = 0;
                let today = 0;
                let performanceList = JSON.parse(this.performanceList);
                let affiliatedPartnerList = JSON.parse(this.affiliatedPartnerList);

                this.affiliatedPartnerTitle = [];
                this.affiliatedPartnerData = Array(12).fill([{ text: '미출고', value: 0 },{ text: '출고중', value: 0 },{ text: '출고완료', value: 0 }]);

                for( let i = 0; i < affiliatedPartnerList.length; i++){
                    qtyTran = 0;
                    let performance = performanceList.filter(item => item.cdPartner === affiliatedPartnerList[i].cdPartner);

                    let waitCount = 0, onGoningCount = 0, completeCount = 0;
                    performance.forEach((entity) => {
                        switch (entity.status) {
                            case 'A': waitCount++; break;
                            case 'B': onGoningCount++; break;
                            case 'C': qtyTran += Number(entity.qtyTran); today += Number(entity.qtyTran); completeCount++; break;
                        }
                    });

                    this.affiliatedPartnerTitle.push(affiliatedPartnerList[i].nmPartner + " - " + qtyTran.toLocaleString() + "kg");
                    this.affiliatedPartnerData[i] = [{ text: '미출고 : ' + waitCount, value: waitCount },{ text: '출고중 : ' + onGoningCount, value: onGoningCount },{ text: '출고완료 : ' + completeCount, value: completeCount }];
                }
                this.chartKey ++;
                this.counter = 0;
                this.today = today.toLocaleString();
            }
        },
        
    }
</script>
<style>
.chartContainer {
    pointer-events: none !important;
}
</style>