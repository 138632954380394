<template>
    <div>
        <div class="chart-align">
            <div class="modal-header" style="display: flex; align-items: center;">
                <h3>비계열사 출고수량</h3>&nbsp;&nbsp;<span><h4>{{ this.today }}kg</h4></span>
            </div>
            <hr/>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <JqxChart :key="'chart1-' + chartKey" class="jqx-chart" ref="unaffiliatedPartnerChart" style="width: 25%; height: 250px;" :title="unaffiliatedPartnerTitle[0]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="unaffiliatedPartnerData[0]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart2-' + chartKey" class="jqx-chart" ref="unaffiliatedPartnerChart" style="width: 25%; height: 250px;" :title="unaffiliatedPartnerTitle[1]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="unaffiliatedPartnerData[1]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart3-' + chartKey" class="jqx-chart" ref="unaffiliatedPartnerChart" style="width: 25%; height: 250px;" :title="unaffiliatedPartnerTitle[2]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="unaffiliatedPartnerData[2]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart4-' + chartKey" class="jqx-chart" ref="unaffiliatedPartnerChart" style="width: 25%; height: 250px;" :title="unaffiliatedPartnerTitle[3]" :description="''" :showLegend="true" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="unaffiliatedPartnerData[3]" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore" @toggle="chartEvent($event)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import JqxChart from "jqwidgets-scripts/jqwidgets-vue/vue_jqxchart.vue";
    export default {
        components: {
            JqxChart,
        },
        props: {
            affiliatedPartnerList: {
                type: String,
                required: true
            },
            unaffiliatedPartnerList: {
                type: String,
                required: true
            },
            performanceList: {
                type: String,
                required: true
            },
            randomParam: {
                type: String,
            }
        },
        watch: {
            randomParam() {
                this.draw();
            }
        },
        mounted() {
            this.draw();
        },
        data: function () {
            return {
                today: '',
                unaffiliatedPartnerTitle: [],
                unaffiliatedPartnerData: Array(4).fill([{ text: '미출고', value: 0 },{ text: '출고중', value: 0 },{ text: '출고완료', value: 0 }]),
                padding: { left: 5, top: 5, right: 5, bottom: 5 },
                titlePadding: { left: 5, top: 5, right: 5, bottom: 5 },
                seriesGroups: [
                    {
                        type: 'donut',
                        useGradientColors: false,
                        toggleLegendSelection: false,
                        series:
                            [
                                {
                                    showLabels: false,
                                    enableSelection: false,
                                    displayText: 'text',
                                    dataField: 'value',
                                    labelRadius: 120,
                                    initialAngle: 90,
                                    radius: 60,
                                    innerRadius: 40,
                                    centerOffset: 0,
                                    rotation: 'counterclockwise',
                                    
                                }
                            ]
                    }
                ],
            }
        },
        beforeCreate: function () {
            this.counter = 0;
            this.chartKey = 0;
        },
        methods: {
            drawBefore: function (renderer, rect) {
                let data = this.unaffiliatedPartnerData[this.counter] || [];
                let value = data.reduce((sum, item) => sum + item.value, 0);
                
                let sz = renderer.measureText(value, 0, { 'class': 'jqx-chart-inner-text' });
                renderer.text(
                    value,
                    rect.x + (rect.width - sz.width) / 2,
                    rect.y + rect.height / 2.25,
                    0,
                    0,
                    0,
                    { 'class': 'jqx-chart-inner-text'}
                );
                this.counter ++;
            },
            chartEvent: function (event) {
                console.log(event);
                this.$refs.unaffiliatedPartnerChart.refresh();
            },
            draw: function () {
                if(!Array.isArray(JSON.parse(this.performanceList))) return;

                let qtyTran = 0;
                let today = 0;
                let performance = [];
                let performanceList = JSON.parse(this.performanceList);
                let affiliatedPartnerList = JSON.parse(this.affiliatedPartnerList);
                let unaffiliatedPartnerList = JSON.parse(this.unaffiliatedPartnerList);

                this.unaffiliatedPartnerTitle = [];
                this.unaffiliatedPartnerData = Array(4).fill([{ text: '미출고', value: 0 },{ text: '출고중', value: 0 },{ text: '출고완료', value: 0 }]);

                for( let i = 1; i < 4; i++){
                    qtyTran = 0;
                    let targetCdPartners = unaffiliatedPartnerList.filter(item => item.chartIdx === i.toString()).map(item => item.cdPartner);

                    performance = performanceList.filter(item => targetCdPartners.includes(item.cdPartner));

                    let waitCount = 0, onGoningCount = 0, completeCount = 0;
                    performance.forEach((entity) => {
                        switch (entity.status) {
                            case 'A': waitCount++; break;
                            case 'B': onGoningCount++; break;
                            case 'C': qtyTran += Number(entity.qtyTran); today += Number(entity.qtyTran); completeCount++; break;
                        }
                    });

                    let firstPartner = unaffiliatedPartnerList.find(item => item.chartIdx === i.toString());

                    this.unaffiliatedPartnerTitle.push(firstPartner.nmPartner + " - " + qtyTran.toLocaleString() + "kg");
                    this.unaffiliatedPartnerData[i-1] = [{ text: '미출고 : ' + waitCount, value: waitCount },{ text: '출고중 : ' + onGoningCount, value: onGoningCount },{ text: '출고완료 : ' + completeCount, value: completeCount }];
                }

                let partnerList = unaffiliatedPartnerList.concat(affiliatedPartnerList).map(item => item.cdPartner);  // unaffiliatedPartnerList의 cdPartner 값만 추출
                performance = performanceList.filter(item => !partnerList.includes(item.cdPartner));  // unaffiliatedPartnerList에 포함되지 않은 항목만 필터링

                qtyTran = 0;
                let waitCount = 0, onGoningCount = 0, completeCount = 0;

                // 나머지 performance 데이터 처리
                performance.forEach((entity) => {
                    switch (entity.status) {
                        case 'A': waitCount++; break;
                        case 'B': onGoningCount++; break;
                        case 'C': qtyTran += Number(entity.qtyTran); today += Number(entity.qtyTran); completeCount++; break;
                    }
                });

                // 결과 데이터 업데이트
                this.unaffiliatedPartnerTitle.push("기타 - " + qtyTran.toLocaleString() + "kg");  // 기타로 구분
                this.unaffiliatedPartnerData[3] = [
                    { text: '미출고 : ' + waitCount, value: waitCount },
                    { text: '출고중 : ' + onGoningCount, value: onGoningCount },
                    { text: '출고완료 : ' + completeCount, value: completeCount }
                ];
                this.chartKey++;
                this.counter = 0;
                this.today = today.toLocaleString();
            }
        },
    }
</script>
<style>
.chartContainer {
    pointer-events: none !important;
}
</style>