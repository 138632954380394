<template>
    <div>
        <div class="chart-align">
            <div class="modal-header" style="display: flex; align-items: center; justify-content: space-between;">
                <h3>금일 출고수량</h3>&nbsp;&nbsp;<span style="position: relative; left: -8%;"><h4>{{ this.today }}kg</h4></span>
                <h3>금월 출고수량</h3>&nbsp;&nbsp;<span style="position: relative; left: -8%;"><h4>{{ this.qtyTranThisMonth }}kg</h4></span>
                <h3>금월 출고계획</h3>&nbsp;&nbsp;<span style="position: relative; left: -8%;"><h4>{{ this.qtyTranMonthlyPlan }}kg</h4></span>
                <h3>달성률</h3>&nbsp;&nbsp;<span style="position: relative; left: -8%;"><h4>{{ this.qtyTranMonthlyPercent }}</h4></span>
            </div>
            <hr/>
            <div class="modal-header" style="display: flex; align-items: center;">
                <h3>신대양제지 시화공장</h3>&nbsp;&nbsp;<span><h4>{{ this.today2000 }}kg</h4></span>
            </div>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <JqxChart :key="'chart1-' + chartKey" class="jqx-chart" ref="dailyOrderChart2000" style="width: 25%; height: 250px;" :title="'금일 주문건수'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="dailyCountData2000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme25'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart2-' + chartKey" class="jqx-chart" ref="waitChart2000" style="width: 25%; height: 250px;" :title="'미출고'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="waitCountData2000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart3-' + chartKey" class="jqx-chart" ref="onGoingChart2000" style="width: 25%; height: 250px;" :title="'출고중'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="onGoingCountData2000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme07'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart4-' + chartKey" class="jqx-chart" ref="completeChart2000" style="width: 25%; height: 250px;" :title="'출고완료'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="completeCountData2000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme25'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                    </div>
                </div>
            </div>
            <div class="modal-header" style="display: flex; align-items: center;">
                <h3>신대양제지 반월공장</h3>&nbsp;&nbsp;<span><h4>{{ this.today9000 }}kg</h4></span>
            </div>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <JqxChart :key="'chart5-' + chartKey" class="jqx-chart" ref="dailyOrderChart9000" style="width: 25%; height: 250px;" :title="'금일 주문건수'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="dailyCountData9000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme25'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart6-' + chartKey" class="jqx-chart" ref="waitChart9000" style="width: 25%; height: 250px;" :title="'미출고'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="waitCountData9000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart7-' + chartKey" class="jqx-chart" ref="onGoingChart9000" style="width: 25%; height: 250px;" :title="'출고중'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="onGoingCountData9000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme07'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart :key="'chart8-' + chartKey" class="jqx-chart" ref="completeChart9000" style="width: 25%; height: 250px;" :title="'출고완료'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="completeCountData9000" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme25'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import JqxChart from "jqwidgets-scripts/jqwidgets-vue/vue_jqxchart.vue";
    export default {
        components: {
            JqxChart,
        },
        props: {
            monthlyPlan: {
                type: String,
                required: true
            },
            performanceList: {
                type: String,
                required: true
            },
            randomParam: {
                type: String,
                required: true
            }
        },
        data: function () {
            return {
                today: '',
                qtyTranThisMonth: '',
                qtyTranMonthlyPlan: '',
                qtyTranMonthlyPercent: '',
                today2000: '',
                today9000: '',

                dailyCountData2000: [{ text: '전체 주문건수', value: 0 }],
                waitCountData2000: [{ text: '미출고', value: 0 },{ text: '', value: 0 }],
                onGoingCountData2000: [{ text: '출고중', value: 0 },{ text: '', value: 0 }],
                completeCountData2000: [{ text: '출고완료', value: 0 },{ text: '', value: 0 }],

                dailyCountData9000: [{ text: '전체 주문건수', value: 0 }],
                waitCountData9000: [{ text: '미출고', value: 0 },{ text: '', value: 0 }],
                onGoingCountData9000: [{ text: '출고중', value: 0 },{ text: '', value: 0 }],
                completeCountData9000: [{ text: '출고완료', value: 0 },{ text: '', value: 0 }],

                padding: { left: 5, top: 5, right: 5, bottom: 5 },
                titlePadding: { left: 5, top: 5, right: 5, bottom: 5 },
                seriesGroups: [
                    {
                        type: 'donut',
                        useGradientColors: false,
                        series:
                            [
                                {
                                    showLabels: false,
                                    enableSelection: false,
                                    displayText: 'text',
                                    dataField: 'value',
                                    labelRadius: 120,
                                    initialAngle: 90,
                                    radius: 60,
                                    innerRadius: 40,
                                    centerOffset: 0,
                                    rotation: 'counterclockwise',
                                }
                            ]
                    }
                ],
            }
        },
        beforeCreate: function () {
            this.counter = 0;
            this.latencyThreshold = 260;
            this.chartKey = 0;
        },
        watch: {
            randomParam() {
                this.draw();
            }
        },
        mounted() {
            this.draw();
        },
        methods: {
            drawBefore: function (renderer, rect) {
                let value;
                if (this.counter === 0) {
                    value = this.dailyCountData2000[0].value;
                } else if (this.counter === 1) {
                    value = this.waitCountData2000[0].value;
                } else if (this.counter === 2) {
                    value = this.onGoingCountData2000[0].value;
                } else if (this.counter === 3) {
                    value = this.completeCountData2000[0].value;
                } else if (this.counter === 4) {
                    value = this.dailyCountData9000[0].value;
                } else if (this.counter === 5) {
                    value = this.waitCountData9000[0].value;
                } else if (this.counter === 6) {
                    value = this.onGoingCountData9000[0].value;
                } else if (this.counter === 7) {
                    value = this.completeCountData9000[0].value;
                }
                
                let sz = renderer.measureText(value, 0, { 'class': 'jqx-chart-inner-text' });
                renderer.text(
                    value,
                    rect.x + (rect.width - sz.width) / 2,
                    rect.y + rect.height / 2,
                    0,
                    0,
                    0,
                    { 'class': 'jqx-chart-inner-text'}
                );
                this.counter ++;
            },
            draw: function () {
                if(!Array.isArray(JSON.parse(this.performanceList))) return;
                let performanceList = JSON.parse(this.performanceList);
                
                console.log(performanceList);
                // 2000, 9000 그룹 분리
                let performance2000 = performanceList.filter(item => String(item.cdCompany).includes("2000"));
                let performance9000 = performanceList.filter(item => String(item.cdCompany).includes("9000"));

                // 2000 그룹 상태 카운트
                let waitCount2000 = 0, onGoningCount2000 = 0, completeCount2000 = 0;
                performance2000.forEach((entity) => {
                    switch (entity.status) {
                        case 'A': waitCount2000++; break;
                        case 'B': onGoningCount2000++; break;
                        case 'C': completeCount2000++; break;
                    }
                });

                // 9000 그룹 상태 카운트
                let waitCount9000 = 0, onGoningCount9000 = 0, completeCount9000 = 0;
                performance9000.forEach((entity) => {
                    switch (entity.status) {
                        case 'A': waitCount9000++; break;
                        case 'B': onGoningCount9000++; break;
                        case 'C': completeCount9000++; break;
                    }
                });

                // 총 개수 계산
                let totalCount2000 = waitCount2000 + onGoningCount2000 + completeCount2000;
                let totalCount9000 = waitCount9000 + onGoningCount9000 + completeCount9000;

                // 2000 데이터 반영
                this.dailyCountData2000[0].value = totalCount2000;
                this.waitCountData2000[0].value = waitCount2000;
                this.waitCountData2000[1].value = onGoningCount2000 + completeCount2000;
                this.onGoingCountData2000[0].value = onGoningCount2000;
                this.onGoingCountData2000[1].value = waitCount2000 + completeCount2000;
                this.completeCountData2000[0].value = completeCount2000;
                this.completeCountData2000[1].value = waitCount2000 + onGoningCount2000;

                // 9000 데이터 반영
                this.dailyCountData9000[0].value = totalCount9000;
                this.waitCountData9000[0].value = waitCount9000;
                this.waitCountData9000[1].value = onGoningCount9000 + completeCount9000;
                this.onGoingCountData9000[0].value = onGoningCount9000;
                this.onGoingCountData9000[1].value = waitCount9000 + completeCount9000;
                this.completeCountData9000[0].value = completeCount9000;
                this.completeCountData9000[1].value = waitCount9000 + onGoningCount9000;

                // 데이터 변경 트리거
                this.dailyCountData2000 = [...this.dailyCountData2000];
                this.waitCountData2000 = [...this.waitCountData2000];
                this.onGoingCountData2000 = [...this.onGoingCountData2000];
                this.completeCountData2000 = [...this.completeCountData2000];

                this.dailyCountData9000 = [...this.dailyCountData9000];
                this.waitCountData9000 = [...this.waitCountData9000];
                this.onGoingCountData9000 = [...this.onGoingCountData9000];
                this.completeCountData9000 = [...this.completeCountData9000];

                this.counter = 0;

                // 차트 업데이트
                // if (this.$refs.dailyOrderChart2000) this.$refs.dailyOrderChart2000.update();
                // if (this.$refs.waitChart2000) this.$refs.waitChart2000.update();
                // if (this.$refs.onGoingChart2000) this.$refs.onGoingChart2000.update();
                // if (this.$refs.completeChart2000) this.$refs.completeChart2000.update();

                // if (this.$refs.dailyOrderChart9000) this.$refs.dailyOrderChart9000.update();
                // if (this.$refs.waitChart9000) this.$refs.waitChart9000.update();
                // if (this.$refs.onGoingChart9000) this.$refs.onGoingChart9000.update();
                // if (this.$refs.completeChart9000) this.$refs.completeChart9000.update();

                this.today = performanceList.filter(item => item.status === "C").reduce((sum, item) => sum + Number(item.qtyTran), 0).toLocaleString();
                this.today2000 = performanceList.filter(item => item.status === "C").reduce((sum, item) => sum + Number(item.qty2000), 0).toLocaleString();
                this.today9000 = performanceList.filter(item => item.status === "C").reduce((sum, item) => sum + Number(item.qty9000), 0).toLocaleString();

                let monthlyPlan = JSON.parse(this.monthlyPlan);
                const qtyTranThisMonth = monthlyPlan.find(item => item.TYPE === "NOW").QTY_TRAN;
                const qtyTranMonthlyPlan = monthlyPlan.find(item => item.TYPE === "PLAN").QTY_TRAN;

                const qtyTranMonthlyPercent = isFinite(qtyTranThisMonth / qtyTranMonthlyPlan) ? qtyTranThisMonth / qtyTranMonthlyPlan : 1;

                this.qtyTranThisMonth = qtyTranThisMonth.toLocaleString();
                this.qtyTranMonthlyPlan = qtyTranMonthlyPlan.toLocaleString();
                this.qtyTranMonthlyPercent = (qtyTranMonthlyPercent * 100).toFixed(1) + "%";

                this.chartKey++;
            }
        },
        
    }
</script>