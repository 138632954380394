<template>
    <div>
        
        
        <JqxTabs ref="myTabs" :width="'clac(100%-3px)'" :height="'150%'" :position="'top'" :animationType="'none'" :selectionTracker='false' :theme="'eyh'" @selected="tabSelected()">
            <ul>
                <li style="margin-left: 30px;">금일 출고현황</li>
                <li>계열사 출고현황</li>
                <li>비계열사 출고현황</li>
            </ul>
            <div>
                <div class="wrap-aboutGrid">
                    <button class="btn sm" @click="toggleFullScreen()">풀스크린</button>
                </div>
                <MainDashboard1 :monthlyPlan = "monthlyPlan" :performanceList = "performanceList" :randomParam = "randomParam"/>
            </div>
            <div>
                <div class="wrap-aboutGrid">
                    <button class="btn sm" @click="toggleFullScreen()">풀스크린</button>
                </div>
                <MainDashboard2 :affiliatedPartnerList = "affiliatedPartnerList" :performanceList = "performanceList" :randomParam = "randomParam"/>
            </div>
            <div>
                <div class="wrap-aboutGrid">
                    <button class="btn sm" @click="toggleFullScreen()">풀스크린</button>
                </div>
                <MainDashboard3 :affiliatedPartnerList = "affiliatedPartnerList" :unaffiliatedPartnerList = "unaffiliatedPartnerList" :performanceList = "performanceList" :randomParam = "randomParam"/>
            </div>
        </JqxTabs>
        <div v-for="(item, index) in noticeList" :key="item.noticeNo">
            <NoticeModal
                :notice-no="item.noticeNo"
                :title="item.noticeTitle"
                :content="item.noticeContext"
                :position-x="(index % modalsPerRow) * (modalXSize + modalGap) + startX"
                :position-y="Math.floor(index / modalsPerRow) * (modalYSize + modalGap) + startY"
                @closeModal="closeModal"
            />
        </div>
        <div v-if="isOnBackground" class="modal-background"></div>
    </div>
</template>

<script>
import api from '@/api/api-interceptors';
import TokenService from '@/api/tokenService';
import MainDashboard1 from '@/components/dashboard/MainDashboard1.vue';
import MainDashboard2 from '@/components/dashboard/MainDashboard2.vue';
import MainDashboard3 from '@/components/dashboard/MainDashboard3.vue';
import NoticeModal from '@/components/dashboard/NoticeModal.vue';
import JqxTabs from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxtabs.vue';

export default {
    components: {
        MainDashboard1,
        MainDashboard2,
        MainDashboard3,
        NoticeModal,
        JqxTabs
    },
    data: function () {
        return {
            user: TokenService.getUser(),
            noticeList: [],
            modalGap: 15,
            modalXSize: 500,
            modalYSize: 200,
            startX: 250,
            startY: 100,
            modalsPerRow: 2,
            isOnBackground: false,
            cntCloseModal: 0,
            isContinue: true,
            monthlyPlan: JSON.stringify({}),
            affiliatedPartnerList: JSON.stringify({}),
            unaffiliatedPartnerList: JSON.stringify({}),
            performanceList: JSON.stringify({}),
            randomParam: 0
        };
    },
    created: function () {
        if (!this.user) {
            console.log('you have to login');
            this.$router.push('/login');
        }
    },
    mounted() {
        var hideModalList = TokenService.getHideModalList();

        api.post('/admin/notice/list', {
            noticeWho: 'TMS',
            today: this.$store.getters.getToday,
        }).then((response) => {
            var data = response.data.noticeList;
            this.noticeList = data.filter((v) => hideModalList.includes(v.noticeNo) == false);
            if (this.noticeList.length > 0) this.isOnBackground = true;
        })
        .catch((error) => {
            this.isOnBackground = false;
            alert(error.returnMessage);
        });

        this.retrivePerformance();

        setTimeout(() => {
            this.retrivePerformance();
        }, 1000*10);
    },
    methods: {
        closeModal() {
            this.cntCloseModal += 1;
            if (this.noticeList.length == this.cntCloseModal) {
                this.isOnBackground = false;
            }
        },
        toggleFullScreen: function() {
            this.fullScreen = !this.fullScreen;

            let nav = document.querySelector('nav');
            let section = document.querySelector('section');
            let header = document.querySelector('.page-information');

            if(this.fullScreen){
                header.style.display = 'none';
                nav.style.display = 'none';
                section.style.position = 'static';
            }else{
                header.style.display = 'flex';
                nav.style.display = 'flex';
                section.style.position = 'absolute';
            }
        },
        retrivePerformance: function() {
            if(!this.isContinue) return false;
            api.post('/perfomance/deliveryStatus/retrivePerformance', {})
            .then((response) => {
                this.monthlyPlan = JSON.stringify(response.data.monthlyPlan);
                this.affiliatedPartnerList = JSON.stringify(response.data.affiliatedPartnerList);
                this.unaffiliatedPartnerList = JSON.stringify(response.data.unaffiliatedPartnerList);
                this.performanceList = JSON.stringify(response.data.performanceHistoryList);

                this.randomParam = JSON.stringify(Math.random());
            })
            .catch((error) => {
                // alert(error.returnMessage);
            })
        },
        tabSelected: function() {
            this.retrivePerformance();
        }
    },
    beforeDestroy() {
        //실시간 갱신여부 => false
        this.isContinue = false;
    },
};
</script>

<style>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(17, 17, 17, 0.4);
}
</style>
