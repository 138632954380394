import axios from 'axios';

const instance = axios.create({
    baseURL:
        window.location.protocol === 'https:' ? process.env.VUE_APP_API_URL_HTTPS : process.env.VUE_APP_API_URL_HTTP,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export default instance;
